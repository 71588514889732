import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col, Button, ButtonGroup } from "reactstrap";
import CommonSection from "../components/UI/common-section/CommonSection";
import Helmet from "../components/Helmet/Helmet";

import yapeImg from "../assets/images/yape.png";
import plinImg from "../assets/images/plin.webp";
import efectivoImg from "../assets/images/efectivoImg.jpg";
import { obtenerFechaActual, obtenerHoraActual } from "../helpers/dateHelper";

import "../styles/checkout.css";
import useProductos from "../hooks/useProductos";
import Mapa from "../components/UI/components/Mapa";
import { MdLocalLaundryService } from "react-icons/md";
import { calculateDistanceAndPrice } from "../helpers/calculateDistanceandPrice";

const Checkout = () => {
    const cartItems = useSelector((state) => state.cart.cartItems);

    const [enterName, setEnterName] = useState("");
    const [enterNumber, setEnterNumber] = useState("");
    const [enterCountry, setEnterCountry] = useState("");
    const [pagaCon, setPagaCon] = useState("");
    const [rSelected, setRSelected] = useState("Efectivo");
    const [itemIds, setItemIds] = useState([]);
    const [gpsArray, setGpsArray] = useState([]);
    const [delivery, setDelivery] = useState(0);

    const shippingInfo = [];
    const cartTotalAmount = useSelector((state) => state.cart.totalAmount);
    const shippingCost = 0;

    const totalAmount = cartTotalAmount + Number(shippingCost);

    const { tiendas, obtenerTiendas, latitude, longitude, deleteLocation } = useProductos();

    useEffect(() => {
        obtenerTiendas();
    }, []);

    const getTiendaNameByItemId = (itemId) => {
        const tiendaEncontrada = tiendas.find(
            (tienda) => tienda._id === itemId
        );
        return tiendaEncontrada
            ? tiendaEncontrada.nombre
            : "Tienda no encontrada";
    };

    

    useEffect(() => {
        // Aquí podrías obtener tus productos de alguna API o de otro lugar
        // Por ahora, estoy simulando un arreglo de productos

        // Ahora filtramos los itemId únicos
        const itemIdSet = new Set();
        const itemIds = cartItems
            .map((producto) => {
                if (!itemIdSet.has(producto.itemId)) {
                    itemIdSet.add(producto.itemId);
                    return producto.itemId;
                }
                return null;
            })
            .filter((itemId) => itemId !== null);

        // Actualizamos el estado con los itemIds únicos
        setItemIds(itemIds);
    }, [cartItems]);

    

    const constructWhatsAppMessage = () => {
        // Construir el mensaje con los elementos del carrito
        const itemsMessage = cartItems
            .map((item) => {
                // Verificar si 'prodMaster' está definido
                const productMaster = item.prodMaster ? item.prodMaster : "";

                return ` - x${item.quantity} ${productMaster} ${item.name} ${
                    item.opcion
                } (${getTiendaNameByItemId(item.itemId)}) %0AP.u. S/. ${
                    item.price
                } %0A `;
            })
            .join("%0A");
        // Número de WhatsApp específico al que enviar el mensaje
        const phoneNumber = "+51952686550";

        // Combinar el mensaje con información adicional si es necesario
        const additionalInfo = `%F0%9F%91%8B Hola,vengo de app.warasdelivery.com %0A %F0%9F%93%85 ${obtenerFechaActual()} %E2%8F%B0 ${obtenerHoraActual()} %0A%0A *Tipo de servicio: Domicilio* %0A%0A%F0%9F%93%9DPedido%0A`;
        const postInfo = `Nombre: ${enterName} %0ATelefono: ${enterNumber}%0ADireccion: ${enterCountry} %0AGPS: ${latitude}, ${longitude}  %0AMetodo de pago: ${rSelected}%0A%0A `;
        const costosMsg = `%F0%9F%92%B2 Costos%0A Costo de los productos:S/. ${cartTotalAmount}%0A Costo de delivery: ${delivery}%0A %0A Total:S/. ${
            cartTotalAmount + delivery
        }%0APaga con:S/. ${pagaCon}`;

        // URL del enlace de WhatsApp con el mensaje codificado
        const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${
            additionalInfo +
            itemsMessage +
            "%0A%0A" +
            costosMsg +
            "%0A%0A" +
            postInfo
        }`;

        // Redirigir al enlace de WhatsApp
        window.location.href = whatsappURL;
    };

    const submitHandler = (e) => {
        e.preventDefault();
        const userShippingAddress = {
            name: enterName,
            phone: enterNumber,
            country: enterCountry,
            pagaCon,
        };

        shippingInfo.push(userShippingAddress);
    };

    useEffect(() => {
        const gpsArray = itemIds
            .map((itemId) => {
                // Busca la tienda correspondiente al itemId actual
                const tienda = tiendas.find((tienda) => tienda._id === itemId);

                // Si la tienda se encuentra, devuelve su gps, de lo contrario, devuelve null
                return tienda ? tienda.gps : null;
            })
            .filter((gps) => gps !== null); // Filtra los resultados que no son null

        // Actualiza el estado con los gps encontrados
        setGpsArray(gpsArray);
    }, [itemIds, tiendas]);

    

    //algortimo de haversine

    // Define las coordenadas de inicio y fin

    const parsedCoordinates = gpsArray.map((coordStr) => {
        const [lat, lng] = coordStr.split(",").map(parseFloat);
        return { lat, lng };
    });

    const lastPoint = { lat: latitude, lng: longitude };

    const polygonPoints = [
        { lat: -9.4708529333816, lng: -77.53900559802246 },
        { lat: -9.485075647727568, lng: -77.53754647631835 },
        { lat: -9.495065058783174, lng: -77.54097970385742 },
        { lat: -9.505308135424293, lng: -77.53565820117187 },
        { lat: -9.514873706996264, lng: -77.5344565715332 },
        { lat: -9.525528, lng: -77.538277 },
        { lat: -9.532776690015144, lng: -77.53385575671386 },
        { lat: -9.540902556849755, lng: -77.53282578845214 },
        { lat: -9.540987200276897, lng: -77.52591641802978 },
        { lat: -9.541025701512503, lng: -77.52136739154052 },
        { lat: -9.538676838874489, lng: -77.51857789416503 },
        { lat: -9.52989491183207, lng: -77.51836331744384 },
        { lat: -9.518080324816905, lng: -77.51967012566254 },
        { lat: -9.512526061659475, lng: -77.52197034650264 },
        { lat: -9.508399326193132, lng: -77.52776391797481 },
        { lat: -9.505099580392088, lng: -77.53194441821546 },
        { lat: -9.49451787796985, lng: -77.53507723834485 },
        { lat: -9.468371, lng: -77.535545 },
    ];

    const distancesAndPrices = [];

    for (let i = 0; i < parsedCoordinates.length; i++) {
        const startCoord = parsedCoordinates[i];
        const endCoord =
            i === parsedCoordinates.length - 1
                ? lastPoint
                : parsedCoordinates[i + 1];

        if (endCoord) {
            const distanceAndPrice = calculateDistanceAndPrice(
                startCoord,
                endCoord,
                polygonPoints
            );
            distancesAndPrices.push(distanceAndPrice);
        }
    }

    // Imprime los resultados
    useEffect(() => {
        // Verifica si latitude y longitude son ambos 0
        if (latitude === 0 && longitude === 0) {
            // Establece delivery en 0 y sale de la función
            setDelivery(0);
            return;
        }

        // Verifica si hay elementos en distancesAndPrices
        if (distancesAndPrices.length > 0) {
            // Calcula la suma de los precios en distancesAndPrices
            const totalDelivery = distancesAndPrices.reduce(
                (total, distanceAndPrice) => {
                    return total + distanceAndPrice.price;
                },
                0
            );

            // Establece el valor de delivery como la suma calculada
            setDelivery(totalDelivery);
        } else {
            // Si no hay elementos en distancesAndPrices, establece delivery en 0 o en algún otro valor predeterminado
            setDelivery(0); // O cualquier otro valor predeterminado
        }
    }, [latitude, longitude, distancesAndPrices]);

    return (
        <Helmet title="Checkout">
            <CommonSection title="Confirmar Compra" />
            <section>
                <Container>
                    <Row>
                        <Col lg="8" md="6">
                            <h3 className="mb-4">
                                Para finalizar brindanos los siguientes datos
                            </h3>
                            <form
                                className="checkout__form"
                                onSubmit={submitHandler}
                            >
                                <div className="form__group">
                                    <label>Nombre</label>
                                    <input
                                        type="text"
                                        placeholder="Nombre"
                                        required
                                        onChange={(e) =>
                                            setEnterName(e.target.value)
                                        }
                                    />
                                </div>

                                <div className="form__group">
                                    <label>Telefono</label>
                                    <input
                                        type="number"
                                        placeholder="Numero de telefono (quien recibe)"
                                        required
                                        onChange={(e) =>
                                            setEnterNumber(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="form__group">
                                    <label>Direccion</label>
                                    <input
                                        type="text"
                                        placeholder="Direccion"
                                        required
                                        onChange={(e) =>
                                            setEnterCountry(e.target.value)
                                        }
                                    />

                                    <p>
                                        O tambien prueba arrastrando el marcador
                                    </p>
                                    <p>ubicacion actual:</p>
                                    {latitude && longitude ? (
                                        <div className="mapa">
                                            <Mapa
                                                coordenadas={{
                                                    lat: latitude,
                                                    lng: longitude,
                                                }}
                                            />
                                        </div>
                                    ) : null}
                                </div>

                                <div>
                                    <p>
                                        Se muestra correctamente tu ubicacion?
                                    </p>
                                    <div className="div-buttons-ubi">
                                        
                                        <button type="button" onClick={()=>deleteLocation()} className="button-borrar-ubi">
                                            Esta no es mi ubicacion
                                        </button>
                                    </div>
                                </div>

                                <div>
                                    <h4>Seleccione metodo de pago:</h4>
                                    <ButtonGroup>
                                        <Button
                                            color="primary"
                                            outline
                                            onClick={() =>
                                                setRSelected("Efectivo")
                                            }
                                            active={rSelected === "Efectivo"}
                                        >
                                            <img
                                                className="img-pago"
                                                src={efectivoImg}
                                                alt={"logo"}
                                            />
                                            <p>Efectivo </p>
                                        </Button>
                                        <Button
                                            color="primary"
                                            outline
                                            onClick={() => setRSelected("Yape")}
                                            active={rSelected === "Yape"}
                                        >
                                            <img
                                                className="img-pago"
                                                src={yapeImg}
                                                alt={"logo"}
                                            />
                                            <p>Yape</p>
                                        </Button>
                                        <Button
                                            color="primary"
                                            outline
                                            onClick={() => setRSelected("Plin")}
                                            active={rSelected === "Plin"}
                                        >
                                            <img
                                                className="img-pago"
                                                src={plinImg}
                                                alt={"logo"}
                                            />
                                            <p>Plin</p>
                                        </Button>
                                    </ButtonGroup>
                                </div>

                                {rSelected === "Efectivo" && (
                                    <div className="form__group">
                                        <input
                                            type="text"
                                            placeholder="Con cuanto paga?"
                                            required
                                            onChange={(e) =>
                                                setPagaCon(e.target.value)
                                            }
                                        />
                                    </div>
                                )}

                                <button
                                    type="submit"
                                    className="button-send"
                                    onClick={constructWhatsAppMessage}
                                >
                                    Listo, deseo enviar mi pedido
                                </button>
                            </form>
                        </Col>

                        <Col lg="4" md="6">
                            <div className="checkout__bill">
                                <h6 className="d-flex align-items-center justify-content-between mb-3">
                                    Subtotal: <span>S/. {cartTotalAmount}</span>
                                </h6>
                                <h6 className="d-flex align-items-center justify-content-between mb-3">
                                    Costo de delivery:{" "}
                                    <span>S/. {delivery}</span>
                                </h6>
                                <div className="checkout__total">
                                    <h5 className="d-flex align-items-center justify-content-between">
                                        Total:{" "}
                                        <span>
                                            S/. {totalAmount + delivery}
                                        </span>
                                    </h5>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Helmet>
    );
};

export default Checkout;
