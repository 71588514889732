import "./product-card-2.css";
import { useNavigate } from "react-router-dom";

const ProductCard2 = ({ data, local, adicionalPorTaper }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/producto/" + data._id);
    };

    
    return (
        <div onClick={handleClick} className="contenedor-prod">
            <div className="texto-prod">
                <h1 className="titulo-prod">{data.nombre}</h1>
                <p className="descripcion-prod">{data.descripcion}</p>
                <p>
                    <span className="precio-prod">
                       {data.opcionesUnicas?.length > 0 && <span className="desde-text">Desde</span>} S/. {data.precio + parseFloat(adicionalPorTaper)}
                    </span>
                </p>
            </div>
            <div className="logo-prod">
                {data.cover ? (
                    <>
                        <img
                            className="imagen-prod"
                            src={data.cover}
                            alt={data.nombre}
                        />
                    </>
                ) : (
                    <>
                        <img
                            className="imagen-prod"
                            src={local.urlLogo}
                            alt={local.nombre}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default ProductCard2;
