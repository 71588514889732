import { useState, useEffect, createContext } from "react";
import clienteAxios from "../config/clienteAxios";
import { logDOM } from "@testing-library/react";

const ProductosContext = createContext();

const ProductosProvider = ({ children }) => {
    const [tiendas, setTiendas] = useState(
        JSON.parse(localStorage.getItem("tiendas")) || []
    );
    const [local, setLocal] = useState([]);
    const [productos, setProductos] = useState([]);
    const [tiendaVista, setTiendaVista] = useState("");
    const [modalAgregarProducto, setModalAgregarProducto] = useState(false);
    const [productosPorCategoria, setProductosPorCategoria] = useState([]);
    const [categoriaSelected, setCategoriaSelected] = useState("");
    const [producto, setProducto] = useState([])
    const [cargando, setCargando] = useState(false)
    const [userUbication, setUserUbication] = useState("")

    const [latitude, setLatitude] = useState(() => {
        const storedValue = localStorage.getItem("latitude");
        return storedValue ? parseFloat(storedValue) : 0;
    });
    const [longitude, setLongitude] = useState(() => {
        const storedValue = localStorage.getItem("longitude");
        return storedValue ? parseFloat(storedValue) : 0;
    });


    

    const getLocation = () => {
        if (navigator.permissions && navigator.permissions.request) {
            navigator.permissions
                .query({ name: "geolocation" })
                .then((permissionStatus) => {
                    if (permissionStatus.state === "granted") {
                        navigator.geolocation.getCurrentPosition(
                            (position) => {
                                const latitude = position.coords.latitude;
                                const longitude = position.coords.longitude;
                                // Almacenar en localStorage
                                localStorage.setItem("latitude", latitude.toString());
                                localStorage.setItem("longitude", longitude.toString());
                                // Actualizar los estados de latitud y longitud
                                setLatitude(latitude);
                                setLongitude(longitude);
                            },
                            (error) => {
                                console.error(
                                    "Error al obtener la ubicación:",
                                    error.message
                                );
                            }
                        );
                    } else if (permissionStatus.state === "prompt") {
                        console.log(
                            "El usuario aún no ha decidido si otorgar permisos de geolocalización."
                        );
                    } else {
                        console.log(
                            "El usuario ha denegado los permisos de geolocalización."
                        );
                    }
                });
        } else {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    localStorage.setItem("latitude", latitude.toString());
                    localStorage.setItem("longitude", longitude.toString());
                    setLatitude(latitude);
                    setLongitude(longitude);
                },
                (error) => {
                    console.error(
                        "Error al obtener la ubicación:",
                        error.message
                    );
                }
            );
        }
    };

    const deleteLocation = () => {
        setLatitude(0);
        setLongitude(0)
        localStorage.removeItem("latitude")
        localStorage.removeItem(longitude)
    }

    const obtenerTiendas = async () => {
        try {
            const { data } = await clienteAxios.get("/api/tienda/tiendas");
            setTiendas(data);
            localStorage.setItem("tiendas", JSON.stringify(data));
        } catch (error) {
            
        }
    };

    const handleCategoriaSelected = (categoria) => {
        setCategoriaSelected(categoria);
    };

    const obtenerTienda = async (ruta) => {
        console.log(ruta);
        setCargando(true)
        try {
            const { data } = await clienteAxios(`/api/tienda/${ruta}`); // Envía solo el valor de "ruta"
            setLocal(data);
            console.log(data);
        } catch (error) {
            
        } finally {
            setCargando(false)
        }
    };

    const obtenerProducto = async (productoId) => {

        setCargando(true)
        try {
            const { data } = await clienteAxios(`/api/tienda/obtenerProducto/${productoId}`); // Envía solo el valor de "ruta"
            setProducto(data);
        } catch (error) {
            
        } finally{
            setCargando(false)
        }
    };

    const handleLocalState = async () => {
        setTiendaVista("");
    };

    const handleTiendaVista = async (id) => {
        setTiendaVista(id);
    };

    const obtenerProductosPorId2 = async (id) => {
        
        try {
            const horaActual = new Date().getHours(); // Obtiene la hora actual en formato de 24 horas

            // Intenta obtener la hora almacenada en localStorage
            const horaAlmacenada = localStorage.getItem(`hora_solicitud_${id}`);

            if (!horaAlmacenada || parseInt(horaAlmacenada) !== horaActual) {
                // Si la hora almacenada es diferente de la hora actual o no existe, hacer la solicitud al API
                const { data } = await clienteAxios.post(
                    "/api/tienda/obtenerProductosPorTienda",
                    { idLocal: id }
                );
                console.log(data);

                // Establecer el estado de productos con los datos recibidos
                setProductos(data);

                // Guardar la hora actual en localStorage
                localStorage.setItem(
                    `hora_solicitud_${id}`,
                    horaActual.toString()
                );

                // Guardar los datos en localStorage para futuras consultas
                localStorage.setItem(`productos_${id}`, JSON.stringify(data));
            } else {
                // Si la hora almacenada es la misma que la hora actual, utilizar los datos existentes en localStorage
                const productosLocalStorage = localStorage.getItem(
                    `productos_${id}`
                );
                if (productosLocalStorage) {
                    setProductos(JSON.parse(productosLocalStorage));
                }
            }
        } catch (error) {
            
        } finally {
            
        }
    };

    const obtenerProductosPorId = async (id, version) => {
        try {
            // Realizar la solicitud a la API con idLocal y version
            const { data } = await clienteAxios.post(
                "/api/tienda/obtenerProductosPorTienda",
                { idLocal: id }
            );
            console.log(data);
    
            // Establecer el estado de productos con los datos recibidos
            setProductos(data);
        } catch (error) {
            if (error.response && error.response.status === 204) {
                console.log("La versión proporcionada es igual o mayor a la versión actual. No se retornaron productos.");
            } else {
                console.error("Error al obtener productos:", error);
            }
        } finally {
            // Aquí puedes realizar cualquier limpieza o acción final si es necesario
        }
    };


    

    

    const obtenerProductosPorCategoria = async (categoria) => {
        try {
            // Realiza la solicitud para obtener productos por categoría
            const { data } = await clienteAxios.post(
                "/api/tienda/obtenerProductosPorCategoria",
                { categoria: categoria }
            );

            // Establece el estado de productos con los datos recibidos
            setProductosPorCategoria(data);
        } catch (error) {
            
        }
    };

    const handleModalAgregarProducto = () => {
        setModalAgregarProducto(!modalAgregarProducto);
    };

    return (
        <ProductosContext.Provider
            value={{
                tiendas,
                obtenerTiendas,
                obtenerTienda,
                local,
                obtenerProductosPorId,
                productos,
                handleLocalState,
                handleTiendaVista,
                tiendaVista,
                modalAgregarProducto,
                handleModalAgregarProducto,
                obtenerProductosPorCategoria,
                productosPorCategoria,
                handleCategoriaSelected,
                categoriaSelected,
                obtenerProducto,
                producto,
                cargando,
                userUbication,
                latitude,
                longitude,
                getLocation,
                deleteLocation
            }}
        >
            {children}
        </ProductosContext.Provider>
    );
};

export { ProductosProvider };

export default ProductosContext;
