import React, { useState, useEffect } from "react";
import Helmet from "../components/Helmet/Helmet";
import useProductos from "../hooks/useProductos";

import { Container, Row, Col } from "reactstrap";


import "../styles/all-foods.css";
import "../styles/pagination.css";
import LocalCard from "../components/UI/product-card/LocalCard";

import { hora, diaSemana } from "../components/Helpers/HelperDate";
import Categories from "../components/categorias/Categories";

const AllFoods = () => {
    const {
        tiendas,
        obtenerTiendas,
        handleLocalState,
        categoriaSelected,
        handleCategoriaSelected,
    } = useProductos();

    const [searchTerm, setSearchTerm] = useState("");

    const [pageNumber, setPageNumber] = useState(0);

    const tiendasFiltradas = tiendas.filter((tienda) => {
        // Si 'categoriaSelected' no está definido o es una cadena vacía, muestra todas las tiendas
        if (!categoriaSelected || categoriaSelected.trim() === "") {
            return true;
        }

        // Comprueba si 'categoriaSelected' está incluido en 'tag' de la tienda
        return tienda.tags?.includes(categoriaSelected);
    });

    const searchedProduct = tiendasFiltradas.filter((item) => {
        if (searchTerm.value === "") {
            return item;
        }
        if (item.nombre.toLowerCase().includes(searchTerm.toLowerCase())) {
            return item;
        }
    });

    const productPerPage = 100;
    const visitedPage = pageNumber * productPerPage;
    const displayPage = searchedProduct.slice(
        visitedPage,
        visitedPage + productPerPage
    );

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    

    useEffect(() => {
        obtenerTiendas();
        handleLocalState();
    }, []);

    function convertirDiaSemana(diaSemana) {
        // Ajustar el valor para que coincida con la convención de diasAbiertos
        return (diaSemana + 6) % 7;
      }

      const localesAbiertos = displayPage.filter(establecimiento => {
        // Convertir el día de la semana actual al formato de diasAbiertos
        const diaSemanaActualConvertido = convertirDiaSemana(diaSemana);
      
        // Verificar si el día actual está en el arreglo diasAbiertos
        if (establecimiento.diasAbiertos.includes(diaSemanaActualConvertido.toString())) {
          // Obtener las horas de inicio y fin
          const [horaInicio, horaFin] = establecimiento.horaInicioFin;
      
          // Verificar si la hora actual está dentro del rango de horas de apertura
          if (horaInicio <= hora && hora < horaFin) {
            return true; // El establecimiento está abierto en este momento
          }
        }
        return false; // El establecimiento no está abierto en este momento
      });
    

      const localesCerrados = displayPage.filter(establecimiento => {
        // Convertir el día de la semana actual al formato de diasAbiertos
        const diaSemanaActualConvertido = convertirDiaSemana(diaSemana);
      
        // Verificar si el día actual no está en el arreglo diasAbiertos
        if (!establecimiento.diasAbiertos.includes(diaSemanaActualConvertido.toString())) {
          return true; // El establecimiento está cerrado hoy
        }
      
        // Obtener las horas de inicio y fin
        const [horaInicio, horaFin] = establecimiento.horaInicioFin;
      
        // Verificar si la hora actual está fuera del rango de horas de apertura
        if (hora < horaInicio || hora >= horaFin) {
          return true; // El establecimiento está cerrado en este momento
        }
      
        return false; // El establecimiento está abierto en este momento
      });
      
    const localesOrdenados = (locales) =>
        locales.sort((a, b) => a.nombre.localeCompare(b.nombre));

        

    return (
        <Helmet title="All-Foods">
            <div>
                <Container>
                    <Row>
                        <Col lg="6" md="6" sm="6" xs="12">
                            <div className="search__widget d-flex container-search ">
                                <input
                                    type="text"
                                    placeholder="Buscar restaurante..."
                                    value={searchTerm}
                                    onChange={(e) =>
                                        setSearchTerm(e.target.value)
                                    }
                                    className="input-busqueda-local"
                                />
                                <span>
                                    <i className="ri-search-line"></i>
                                </span>
                            </div>
                        </Col>
                        <Col lg="6" md="6" sm="6" xs="12" className="mb-5">
                            <div className="sorting__widget text-end"></div>
                        </Col>

                        {/* <div className="slider-recomendados">
                            <TestimonialSlider />
                        </div> */}

                        <div>
                            <Categories />
                        </div>

                        <div>
                            {categoriaSelected && (
                                <div className="div-reset-filtro-categorias">
                                    <div>
                                        <p>Mostrando: {categoriaSelected}</p>
                                    </div>
                                    <div
                                        onClick={() =>
                                            handleCategoriaSelected("")
                                        }
                                        className="equis"
                                    >
                                        X
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="div-titulo-tiendas">
                            <h2 className="titulo-tiendas">Tiendas :</h2>{" "}
                            {categoriaSelected && (
                                <p className="parrafo-tiendas">
                                    ({categoriaSelected}){" "}
                                </p>
                            )}
                            <p className="parrafo-tiendas">
                                {localesAbiertos.length} abiertos{" "}
                            </p>
                        </div>

                        <>
                            {localesOrdenados(localesAbiertos).map((item) => (
                                <Col
                                    lg="4"
                                    md="6"
                                    sm="6"
                                    xs="12"
                                    key={item.id}
                                    className=""
                                >
                                    <LocalCard item={item} />
                                </Col>
                            ))}

                            {localesOrdenados(localesCerrados).map((item) => (
                                <Col
                                    lg="4"
                                    md="6"
                                    sm="6"
                                    xs="12"
                                    key={item.id}
                                    className=""
                                >
                                    <LocalCard key={item._id} item={item} />
                                </Col>
                            ))}
                        </>
                    </Row>
                </Container>
            </div>
        </Helmet>
    );
};

export default AllFoods;
