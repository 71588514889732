import "./LocalCard.css";
import { useNavigate } from "react-router-dom";
import { hora, diaAjustado, diaSemana } from "../../Helpers/HelperDate";
import { RiClockwiseLine, RiTimeFill } from "react-icons/ri";
import { useEffect } from "react";
import Swal from "sweetalert2";
import useProductos from "../../../hooks/useProductos";
import warasLogo from "../../../assets/images/warasColor.png"

const LocalCard = ({ item }) => {
    const navigate = useNavigate();
    const { handleTiendaVista, tiendaVista } = useProductos();

    useEffect(() => {
        const scrollToTop = () => window.scrollTo(0, 0);
        scrollToTop();
    }, []);

    const handleClick = () => {
        const estaAbierto = verificarApertura(item.diasAbiertos, item.horaInicioFin);
        if (estaAbierto) {
            navigate("/" + item.ruta);
            handleTiendaVista(item._id);
        } else {
            const tituloCapitalizado = item.nombre.charAt(0).toUpperCase() + item.nombre.slice(1);
            Swal.fire({
                title: `<p className="titulo">${tituloCapitalizado} Cerrado ahora</p>`,
                text: "El local actualmente se encuentra cerrado, pero puedes ver los productos",
                icon: "info",
                imageUrl: item.logo,
                imageWidth: 150,
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonColor: "#01AF15 ",
                cancelButtonColor: "#d33",
                background: "#EBEBEB",
                confirmButtonText: "Ver los productos",
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate("/" + item.ruta);
                    handleTiendaVista(item._id);
                }
            });
        }
    };
    

    function convertirDiaSemana(diaSemana) {
        // Ajustar el valor para que coincida con la convención de diasAbiertos
        return (diaSemana + 6) % 7;
      }

      const verificarApertura = (diasAbiertos, horaInicioFin) => {
        const diaSemanaActualConvertido = convertirDiaSemana(diaSemana);
        return (
            diasAbiertos?.includes(diaSemanaActualConvertido.toString()) &&
            hora >= horaInicioFin[0] &&
            hora < horaInicioFin[1]
        );
    };

    return (
        <div
            onClick={handleClick}
            className={verificarApertura(item.diasAbiertos, item.horaInicioFin) ? "contenedor" : "contenedor-cerrado"}
        >
            <div className="texto-local">
                <h2 className="titulo">{item.nombre}</h2>
                <div>
                    <p className="direccion">{item.ubicacion}</p>
                </div>

                <p className="horario-card">{item.horario}</p>
                <div className="tiempo-div">
                    <RiTimeFill className="tiempo-icon" />
                    <p className="tiempo">{item.tiempoPreparacion} mins.</p>
                </div>
                <div className="tags-div">
                    {item.tags?.map((tag) => (
                        <p className="tag">{tag} </p>
                    ))}
                </div>
            </div>

            <div>
                {verificarApertura(item.diasAbiertos, item.horaInicioFin) ? (
                    <p className="estado-abierto">Abierto</p>
                ) : (
                    <p className="estado-cerrado">Cerrado</p>
                )}
                <img
                    src={item.urlLogo || warasLogo}
                    alt={item.nombre}
                    className="logoLocal"
                />
            </div>
        </div>
    );
};

export default LocalCard;
