import React, { useEffect } from "react";
import Mapa from "../components/UI/components/Mapa";
import useProductos from "../hooks/useProductos";
import { useNavigate } from "react-router-dom";

const GetLocation = () => {
    const { latitude, longitude, getLocation } = useProductos();

    useEffect(() => {
        getLocation();
    }, [getLocation, latitude, longitude]);

    const navigate = useNavigate()

    return (
        <div>
            <div>
                <p>
                    Debes dar permiso al app para acceder a tu ubicacion
                </p>
            </div>
            {latitude && longitude ? (
                <div className="mapa">
                    <Mapa
                        coordenadas={{
                            lat: latitude,
                            lng: longitude,
                        }}
                    />
                </div>
            ) : null}

            <div>
                <button type="button" onClick={()=>navigate(-1)} className="button-ubicacion">
                    Perfecto! es mi ubicacion actual
                </button>
            </div>
        </div>
    );
};

export default GetLocation;
