export function calculateDistanceAndPrice(start, end, polygonPoints) {
    const distanceInMeters = calculateHaversineDistance(
        start.lat,
        start.lng,
        end.lat,
        end.lng
    );

    const isInsidePolygon = pointInPolygon(end, polygonPoints);

    let distanceMultiplier = 1;
    if (!isInsidePolygon) {
        distanceMultiplier = 2.1;
    }

    const distanceWithMultiplier = distanceInMeters * distanceMultiplier;

    const distance = {
        distance: distanceWithMultiplier,
        price: Math.ceil((distanceWithMultiplier + 4.5) * 2) / 2,
    };

    return distance;
}

function calculateHaversineDistance(lat1, lon1, lat2, lon2) {
    const R = 6371;

    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
            Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;

    return distance;
}

function deg2rad(deg) {
    return deg * (Math.PI / 180);
}

function pointInPolygon(point, polygonPoints) {
    let inside = false;
    const x = point.lng,
        y = point.lat;

    for (
        let i = 0, j = polygonPoints.length - 1;
        i < polygonPoints.length;
        j = i++
    ) {
        const xi = polygonPoints[i].lng,
            yi = polygonPoints[i].lat;
        const xj = polygonPoints[j].lng,
            yj = polygonPoints[j].lat;

        const intersect =
            yi > y !== yj > y &&
            x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) {
            inside = !inside;
        }
    }

    return inside;
}
