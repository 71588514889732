import {
    RiMapPin2Fill,
} from "react-icons/ri";
import "./ProfileCard.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FaArrowLeft, FaShareAlt } from "react-icons/fa";
import logoWaras from "../assets/images/warasColor.png"
import warasBanner from "../assets/images/warasBlack.png"

const ProfileCard = ({ local }) => {
    const [latitud, setLatitud] = useState(-9.530023760471668);
    const [longitud, setLongitud] = useState(-77.52888942607683); // Ejemplo de longitud (puedes usar tus propias coordenadas)
    const zoom = 17;
    const ancho = 450;
    const alto = 220;
    const apiKey = "AIzaSyBXPQsHMLeNaWgMd8k97xLKWBl-TRF40pw";

    // Construye la URL del mapa estático
    const urlMapa = `https://maps.googleapis.com/maps/api/staticmap?center=${latitud},${longitud}&zoom=${zoom}&size=${ancho}x${alto}&markers=color:red%7Clabel:%7C${latitud},${longitud}&key=${apiKey}`;

    useEffect(() => {
        if (local.gps) {
            // Separar la cadena de GPS en latitud y longitud
            const coordenadas = local.gps.split(", ");
            if (coordenadas.length === 2) {
                setLatitud(parseFloat(coordenadas[0])); // Convertir la cadena a número
                setLongitud(parseFloat(coordenadas[1])); // Convertir la cadena a número
            }
        }
    }, [local.gps]);

    const navigate = useNavigate();
    const goBackHandler = () => {
        navigate(-1); // Use navigate with a negative value to go back
    };

    const shareHandler = async () => {
        try {
            if (navigator.share) {
                await navigator.share({
                    title: local?.nombre,
                    url: window.location.href,
                });
            } else {
                // Fallback para navegadores que no admiten la API Web Share
                alert(
                    "La funcionalidad de compartir no está disponible en este navegador."
                );
            }
        } catch (error) {
            console.error("Error al intentar compartir:", error);
        }
    };

    return (
        <div className="contenedor-perfil">
            <div className="wrapper">
                <div className="profile-card">
                    <div className="profile-header">
                        <div
                            className="boton-izquierda"
                            onClick={goBackHandler}
                        >
                            <div>
                                <FaArrowLeft />
                            </div>
                        </div>
                        <div onClick={shareHandler} className="boton-derecha">
                            <div>
                                <FaShareAlt />
                            </div>
                        </div>
                        <img className="banner" src={local?.urlBanner} alt="" />
                    </div>
                    <div className="profile-body">
                        <div className="author-img">
                            <img
                                className="logo-local"
                                src={local?.urlLogo || logoWaras}
                                alt={"logo" + local?.nombre}
                            />
                        </div>

                        <div className="nombre">
                            <div className="name">{local?.nombre}</div>
                            <p className="ubicacion">
                                <RiMapPin2Fill /> {local?.direccion}
                            </p>
                        </div>
                        <p className="horario-local?">
                            Horario: {local?.horario}
                        </p>
                        {local.gps?.split(", ")[0] === latitud.toString() && (
                            <div>
                                <img src={urlMapa} alt="Mapa Estático" />
                                <div className="punto"></div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileCard;
