
import Header from "../Header/Header.jsx";
import Routes from "../../routes/Routers";

import Carts from "../UI/cart/Carts.jsx";
import { useSelector } from "react-redux";
import useProductos from "../../hooks/useProductos.jsx";

const Layout = () => {

  const { latitude, longitude } = useProductos()

  const showCart = useSelector((state) => state.cartUi.cartIsVisible);

  

    return (
    <div>
      <Header />

      {showCart && <Carts />}

      <div>
        <Routes />
      </div>
      
      


    </div >
  );
};

export default Layout;
