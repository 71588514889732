import React, { useState } from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';

const Mapa = ({ coordenadas , onMarkerPositionChange}) => {
  const [markerPosition, setMarkerPosition] = useState(coordenadas);

  const onMarkerDragEnd = (e) => {
    const newMarkerPosition = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    };
    setMarkerPosition(newMarkerPosition);
    onMarkerPositionChange(newMarkerPosition); // Llama a la función de devolución de llamada con las nuevas coordenadas
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyBXPQsHMLeNaWgMd8k97xLKWBl-TRF40pw">
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '100%' }}
        center={markerPosition}
        zoom={16}
        options={{
          streetViewControl:false,
          mapTypeControl:false
        }}
        
      >
        <Marker
          position={markerPosition}
          draggable={true}
          onDragEnd={onMarkerDragEnd}
        />
      </GoogleMap>
    </LoadScript>
  );
};

export default Mapa;
