import brocheta from "../../assets/images/iconos/brocheta.png";
import frappe from "../../assets/images/iconos/frappe.png";
import hamburguer from "../../assets/images/iconos/hamburguer.png";
import juice from "../../assets/images/iconos/juice.png";
import maki from "../../assets/images/iconos/maki.png";
import pizza from "../../assets/images/iconos/pizza.png";
import pollo from "../../assets/images/iconos/pollo.png";
import torta from "../../assets/images/iconos/pastel.png";
import sopa from "../../assets/images/iconos/sopa.png";
import arroz from "../../assets/images/iconos/arroz.png";
import "../categorias/categorias.css";

import useProductos from "../../hooks/useProductos";

const categorias = [
    {
        nombre: "Pizzas",
        cover: pizza
    },
    {
        nombre: "Brasas",
        cover: pollo
    },
    {
        nombre: "Anticuchos",
        cover: brocheta
    },
    {
        nombre: "Frappes",
        cover: frappe
    },
    {
        nombre: "Hamburguesas",
        cover: hamburguer
    },
    {
        nombre: "Jugos",
        cover: juice
    },
    {
        nombre: "Chifas",
        cover: arroz,
    },
    {
        nombre: "Postres",
        cover: torta
    },
    {
        nombre: "Sopas",
        cover: sopa
    },
];

const Categories = () => {
    const {
        handleCategoriaSelected,
        categoriaSelected,
    } = useProductos();
    return (
        <div className="categorias-container">
            {categorias.map((item) => (
                <div
                    onClick={() =>
                        handleCategoriaSelected(item.nombre.toLowerCase())
                    }
                    className={
                        categoriaSelected === item.nombre.toLowerCase()
                            ? "item-categoria-selected"
                            : "item-categoria"
                    }
                    key={item.nombre}
                >
                    <img src={item.cover} alt={item.nombre} />
                    <h6>{item.nombre}</h6>
                </div>
            ))}
        </div>
    );
};

export default Categories;
