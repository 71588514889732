import React, { useState, useEffect } from "react";

import products from "../assets/fake-data/products";
import { useParams, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { cartActions } from "../store/shopping-cart/cartSlice";

import "../styles/fooddetails.css";
import useProductos from "../hooks/useProductos";
import { FaArrowLeft, FaShareAlt } from "react-icons/fa";
import {
    MdRadioButtonUnchecked,
    MdRadioButtonChecked,
    MdAdd,
    MdRemove
} from "react-icons/md";
import { MoonLoader } from "react-spinners";
import { TiDelete } from "react-icons/ti";

const FoodDetails = () => {
    const [priceSelectedProd, setPriceSelectedProd] = useState(0);
    const [opcionSelected, setOpcionSelected] = useState("");
    const [animado, setAnimado] = useState(false);
    const [opcionesUnicasSeleccionadas, setOpcionesUnicasSeleccionadas] =
        useState([]);

    const [opcionesMultiplesSeleccionadas, setOpcionesMultiplesSeleccionadas] =
        useState([]);

    const seleccionarOpcionUnica = (opcionIndex, subopcionIndex) => {
        // Obtenemos la opción única seleccionada
        const opcionSeleccionada = producto.opcionesUnicas[opcionIndex];
        // Obtenemos la subopción seleccionada
        const subopcionSeleccionada =
            opcionSeleccionada.opciones[subopcionIndex];

        // Verificamos si ya hay una selección para esta opción única
        const indiceSeleccion = opcionesUnicasSeleccionadas.findIndex(
            (opcion) => opcion.nombre === opcionSeleccionada.nombre
        );

        // Si ya hay una selección, la actualizamos
        if (indiceSeleccion !== -1) {
            const nuevasSelecciones = [...opcionesUnicasSeleccionadas];
            nuevasSelecciones[indiceSeleccion].subopcion =
                subopcionSeleccionada;
            setOpcionesUnicasSeleccionadas(nuevasSelecciones);
        } else {
            // Si no hay una selección, agregamos esta selección
            setOpcionesUnicasSeleccionadas([
                ...opcionesUnicasSeleccionadas,
                {
                    nombre: opcionSeleccionada.nombre,
                    subopcion: subopcionSeleccionada,
                },
            ]);
        }
    };

    const seleccionarOpcionMultiple = (opcionIndex, subopcionIndex) => {
        const opcionSeleccionada = producto.opcionesMultiples[opcionIndex];
        const subopcionSeleccionada =
            opcionSeleccionada.opciones[subopcionIndex];

        const nombreOpcion = opcionSeleccionada.nombre;

        const seleccionesActuales = opcionesMultiplesSeleccionadas.filter(
            (seleccion) => seleccion.nombre === nombreOpcion
        );
        const cantidadSeleccionada = seleccionesActuales.reduce(
            (total, seleccion) => total + seleccion.cantidad,
            0
        );

        if (
            opcionSeleccionada.limiteSeleccion === 0 ||
            cantidadSeleccionada < opcionSeleccionada.limiteSeleccion
        ) {
            const nuevaSeleccion = {
                nombre: nombreOpcion,
                subopcion: subopcionSeleccionada,
                cantidad: 1,
            };

            const nuevasSelecciones = [...opcionesMultiplesSeleccionadas];

            const indiceSeleccion = nuevasSelecciones.findIndex(
                (seleccion) =>
                    seleccion.nombre === nombreOpcion &&
                    seleccion.subopcion.nombre === subopcionSeleccionada.nombre
            );

            if (indiceSeleccion !== -1) {
                nuevasSelecciones[indiceSeleccion].cantidad++;
            } else {
                nuevasSelecciones.push(nuevaSeleccion);
            }

            setOpcionesMultiplesSeleccionadas(nuevasSelecciones);
        }
    };

    const eliminarSubopcion = (nombre) => {
        // Filtra las opcionesUnicasSeleccionadas para eliminar la subopción con el nombre dado
        const nuevasOpciones = opcionesUnicasSeleccionadas.filter(
            (item) => item.nombre !== nombre
        );
        // Actualiza el estado opcionesUnicasSeleccionadas con las nuevas opciones
        setOpcionesUnicasSeleccionadas(nuevasOpciones);
    };

    const removerOpcionMultiple = (opcionIndex, subopcionIndex) => {
        const opcionSeleccionada = producto.opcionesMultiples[opcionIndex];
        const subopcionSeleccionada =
            opcionSeleccionada.opciones[subopcionIndex];

        setOpcionesMultiplesSeleccionadas((prevState) => {
            const newState = [...prevState];
            const index = newState.findIndex(
                (seleccion) =>
                    seleccion.nombre === opcionSeleccionada.nombre &&
                    seleccion.subopcion.nombre === subopcionSeleccionada.nombre
            );

            if (index !== -1) {
                newState[index].cantidad--;

                if (newState[index].cantidad === 0) {
                    newState.splice(index, 1);
                }
            }

            return newState;
        });
    };

    const cadenaOpcionesUnicas = opcionesUnicasSeleccionadas
        .map((item) => `[ ${item.nombre}: ${item.subopcion.nombre} ] `)
        .join("\n");

    const subopcionesAgrupadas = {};
    opcionesMultiplesSeleccionadas.forEach((item) => {
        if (!subopcionesAgrupadas[item.nombre]) {
            subopcionesAgrupadas[item.nombre] = [];
        }
        subopcionesAgrupadas[item.nombre].push(
            ` ${item.subopcion.nombre} (${item.cantidad})`
        );
    });

    // Creamos la cadena de texto formateada
    let cadenaOpcionesMultiples = "";
    for (const nombre in subopcionesAgrupadas) {
        cadenaOpcionesMultiples += `${nombre}:\n`;
        subopcionesAgrupadas[nombre].forEach((subopcion) => {
            cadenaOpcionesMultiples += `  ${subopcion}\n `;
        });
    }

    const { id } = useParams();
    const dispatch = useDispatch();

    const product = products.find((product) => product.id === id);

    const { obtenerProducto, producto, cargando } = useProductos();

    const navigate = useNavigate();

    const goBackHandler = () => {
        navigate(-1); // Use navigate with a negative value to go back
    };

    useEffect(() => {
        obtenerProducto(id);
    }, []);

    const timeNow = Date.now();

    
    const addItem = () => {
        dispatch(
            cartActions.addItem({
                id:
                    producto._id +
                    "-" +
                    cadenaOpcionesUnicas.length * timeNow +
                    "-" +
                    cadenaOpcionesMultiples.length * timeNow,
                name: producto.nombre + " (" + producto.categoria + ")",
                price: priceSelectedProd,
                cover: producto.cover || producto.local.urlLogo,
                itemId: producto.local._id,
                opcion: cadenaOpcionesUnicas + " - " + "[ "+ cadenaOpcionesMultiples + " ]",
            })
        );

        navigate(-1);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [product]);

    useEffect(() => {
        if (producto && producto.opciones?.length > 0) {
            setOpcionSelected(producto.opciones[0].nombre);
        }
    }, [producto]);

    

    const shareHandler = async () => {
        try {
            if (navigator.share) {
                await navigator.share({
                    title: producto.local?.nombre + " - " + producto.nombre,
                    text: producto.descripcion,
                    url: window.location.href,
                });
            } else {
                // Fallback para navegadores que no admiten la API Web Share
                alert(
                    "La funcionalidad de compartir no está disponible en este navegador."
                );
            }
        } catch (error) {
            console.error("Error al intentar compartir:", error);
        }
    };

    

    useEffect(() => {
        // Calcula el precio total de las opciones únicas seleccionadas
        let precioOpcionesUnicas = opcionesUnicasSeleccionadas.reduce(
            (total, opcion) => {
                return total + opcion.subopcion.precio;
            },
            0
        );
    
        // Calcula el precio total de las opciones múltiples seleccionadas
        let precioOpcionesMultiples = opcionesMultiplesSeleccionadas.reduce(
            (total, opcion) => {
                return total + opcion.cantidad * opcion.subopcion.precio;
            },
            0
        );
    
        // Calcula el adicionalPorTaper si existe, de lo contrario establece 0
        let adicionalPorTaper = producto.local?.adicionalPorTaper ? parseFloat(producto.local.adicionalPorTaper) : 0;
    
        // Actualiza el precio seleccionado con el precio base del producto más el precio de las opciones seleccionadas
        setPriceSelectedProd(
            producto.precio +
                adicionalPorTaper +
                precioOpcionesUnicas +
                precioOpcionesMultiples
        );
    }, [opcionesUnicasSeleccionadas, opcionesMultiplesSeleccionadas, producto]);

    useEffect(() => {
        // Establece animado a true cuando priceSelectedProd cambia
        setAnimado(true);

        // Establece animado a false después de un breve tiempo para permitir que la animación ocurra
        const timeout = setTimeout(() => {
            setAnimado(false);
        }, 500); // ajusta este valor según la duración de tu animación

        // Limpia el timeout cuando el componente se desmonta o cuando priceSelectedProd cambia nuevamente
        return () => clearTimeout(timeout);
    }, [priceSelectedProd]);
    

    if (cargando) {
        // Muestra un indicador de carga o un esqueleto mientras se carga
        return (
            <div
                style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 999, // Asegura que esté sobre otros elementos
                }}
            >
                <MoonLoader color="#d50505" size={70} speedMultiplier={0.7} />
            </div>
        );
    }

    return (
        <>
            <div className="div-imagen-prod">
                <div className="boton-izquierda" onClick={goBackHandler}>
                    <div>
                        <FaArrowLeft />
                    </div>
                </div>
                <div onClick={shareHandler} className="boton-derecha">
                    <div>
                        <FaShareAlt />
                    </div>
                </div>
                <img
                    src={
                        producto.cover
                            ? producto.cover
                            : producto.local?.urlLogo
                    }
                    alt={producto.nombre}
                />
            </div>
            <div className="div-texto-prod">
                <div>
                    <h1 className="nombre-prod">
                        {producto.nombre}{" "}
                        <span className="categoria-prod-detalle">
                            {producto.categoria}
                        </span>{" "}
                    </h1>
                    <p className="descripcion-producto">
                        {producto.descripcion}{" "}
                    </p>

                    {/* {producto.local?.adicionalPorTaper ? (
                        <div>
                            <h1 className="precio-prod">
                                {producto.precio !== null
                                    ? "S/. " +
                                      (parseFloat(producto.precio) +
                                          parseFloat(
                                              producto.local.adicionalPorTaper
                                          ))
                                    : ""}
                            </h1>
                        </div>
                    ) : (
                        <div>
                            <h1 className="precio-prod">
                                {producto.precio !== null
                                    ? producto.precio
                                    : ""}
                            </h1>
                        </div>
                    )} */}
                </div>

                <div className="opciones-div">
                    {producto.opcionesUnicas && (
                        <div>
                            {producto.opcionesUnicas.map(
                                (opcion, opcionIndex) => (
                                    <div
                                        className="container-opciones"
                                        key={opcionIndex}
                                    >
                                        <div className="opcion-texto">
                                            <div className="opcion-titulo-limite">
                                                <h3 className="opcion-titulo">
                                                    {opcion.nombre}
                                                </h3>
                                                
                                            </div>
                                            <div>
                                                {opcion.obligatorio && <p className="obligatorio-tag">Obligatorio</p> }
                                            </div>

                                            <div>
                                                <p className="opcion-descripcion">
                                                    {opcion.descripcion}
                                                </p>
                                                <span>
                                                    {opcionesUnicasSeleccionadas.some(
                                                        (item) =>
                                                            item.nombre ===
                                                            opcion.nombre
                                                    ) && (
                                                        <div className="container-listo-opcion-selected">
                                                            <p className="listo-opcion">
                                                                Listo
                                                            </p>
                                                            <div className="container-opcion-escogida">
                                                                <TiDelete
                                                                    className="icono-delete"
                                                                    onClick={() =>
                                                                        eliminarSubopcion(
                                                                            opcion.nombre
                                                                        )
                                                                    }
                                                                />
                                                                <p>
                                                                    {
                                                                        opcionesUnicasSeleccionadas.find(
                                                                            (
                                                                                item
                                                                            ) =>
                                                                                item.nombre ===
                                                                                opcion.nombre
                                                                        )
                                                                            .subopcion
                                                                            .nombre
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </span>
                                            </div>
                                            
                                        </div>

                                        <div
                                            className={
                                                opcionesUnicasSeleccionadas.some(
                                                    (item) =>
                                                        item.nombre ===
                                                        opcion.nombre
                                                ) && "opciones-ocultas"
                                            }
                                        >
                                            {opcion.opciones.map(
                                                (subopcion, subopcionIndex) => {
                                                    const seleccionada =
                                                        opcionesUnicasSeleccionadas.find(
                                                            (seleccion) =>
                                                                seleccion.nombre ===
                                                                    opcion.nombre &&
                                                                seleccion
                                                                    .subopcion
                                                                    .nombre ===
                                                                    subopcion.nombre
                                                        );
                                                    return (
                                                        <div
                                                            className={`opcion-div ${
                                                                seleccionada
                                                                    ? "seleccionada"
                                                                    : ""
                                                            }`}
                                                            key={subopcionIndex}
                                                            onClick={() =>
                                                                seleccionarOpcionUnica(
                                                                    opcionIndex,
                                                                    subopcionIndex
                                                                )
                                                            }
                                                        >
                                                            <div>
                                                                <p className="subopcion-nombre">
                                                                    {
                                                                        subopcion.nombre
                                                                    }
                                                                </p>
                                                                {subopcion.precio >
                                                                    0 && (
                                                                    <p className="subopcion-precio">
                                                                        + S/.{" "}
                                                                        {subopcion.precio.toFixed(
                                                                            2
                                                                        )}
                                                                    </p>
                                                                )}
                                                            </div>
                                                            {seleccionada ? (
                                                                <MdRadioButtonChecked className="icono-check" />
                                                            ) : (
                                                                <MdRadioButtonUnchecked className="icono-check" />
                                                            )}
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    )}

                    {producto.opcionesMultiples && (
                        <div>
                            {producto.opcionesMultiples.map(
                                (opcion, opcionIndex) => (
                                    <div
                                        className="container-opciones"
                                        key={opcionIndex}
                                    >
                                        <div className="opcion-texto">
                                            <div className="opcion-titulo-limite">
                                                <h3 className="opcion-titulo">
                                                    {opcion.nombre}
                                                </h3>
                                                <p className="opcion-limite">
                                                    Selecciona hasta{" "}
                                                    {opcion.limiteSeleccion}{" "}
                                                </p>
                                            </div>
                                            <div>
                                                <p className="opcion-descripcion">
                                                    {opcion.descripcion}
                                                </p>

                                                <span>
                                                    {opcionesMultiplesSeleccionadas.some(
                                                        (item) =>
                                                            item.nombre ===
                                                            opcion.nombre
                                                    ) &&
                                                        opcionesMultiplesSeleccionadas
                                                            .filter(
                                                                (item) =>
                                                                    item.nombre ===
                                                                    opcion.nombre
                                                            )
                                                            .reduce(
                                                                (total, item) =>
                                                                    total +
                                                                    item.cantidad,
                                                                0
                                                            ) ===
                                                            opcion.limiteSeleccion && (
                                                            <p className="listo-opcion">
                                                                Listo
                                                            </p>
                                                        )}
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            {opcion.opciones.map(
                                                (subopcion, subopcionIndex) => {
                                                    const seleccionada =
                                                        opcionesMultiplesSeleccionadas.find(
                                                            (seleccion) =>
                                                                seleccion.nombre ===
                                                                    opcion.nombre &&
                                                                seleccion
                                                                    .subopcion
                                                                    .nombre ===
                                                                    subopcion.nombre
                                                        );
                                                    return (
                                                        <div
                                                            className={`opcion-div ${
                                                                seleccionada
                                                                    ? "seleccionada"
                                                                    : ""
                                                            }`}
                                                            key={subopcionIndex}
                                                        >
                                                            <div>
                                                                <p className="subopcion-nombre">
                                                                    {
                                                                        subopcion.nombre
                                                                    }
                                                                </p>
                                                                {subopcion.precio >
                                                                    0 && (
                                                                    <p className="subopcion-precio">
                                                                        + S/.{" "}
                                                                        {subopcion.precio.toFixed(
                                                                            2
                                                                        )}
                                                                    </p>
                                                                )}
                                                            </div>
                                                            {seleccionada ? (
                                                                <div className="div-botton-add-remove">
                                                                    <div
                                                                        onClick={() =>
                                                                            removerOpcionMultiple(
                                                                                opcionIndex,
                                                                                subopcionIndex
                                                                            )
                                                                        }
                                                                    >
                                                                        <MdRemove className="" />
                                                                    </div>
                                                                    <span className="cant-subopcion">
                                                                        {
                                                                            seleccionada.cantidad
                                                                        }
                                                                    </span>
                                                                    <div
                                                                        onClick={() =>
                                                                            seleccionarOpcionMultiple(
                                                                                opcionIndex,
                                                                                subopcionIndex
                                                                            )
                                                                        }
                                                                    >
                                                                        <MdAdd className="" />
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    onClick={() =>
                                                                        seleccionarOpcionMultiple(
                                                                            opcionIndex,
                                                                            subopcionIndex
                                                                        )
                                                                    }
                                                                >
                                                                    <MdAdd className="icono-check" />
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    )}
                </div>
            </div>

            <div className="div-boton-agregar">
            <button onClick={addItem} className={`boton-agregar ${animado && 'animado' }`}>
                Agregar S/. {priceSelectedProd.toFixed(2)}
            </button>
        </div>
        </>
    );
};

export default FoodDetails;
